import { MethodCode, ModelCode } from '../../@types/Permission';
import { findPermission } from '../../sections/@dashboard/Permissions/utils';
var bookSaleTransaction = function (user) {
    var hasPermissionCreateBookSaleTransaction = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.BOOK_SALE_TRANSACTION, MethodCode.CREATE);
    var hasPermissionListBookSaleTransaction = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.BOOK_SALE_TRANSACTION, MethodCode.LIST);
    var hasPermissionViewBookSaleTransaction = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.BOOK_SALE_TRANSACTION, MethodCode.VIEW);
    //   const hasPermissionDeleteBookStock = findPermission(
    //     user?.permissionGroup,
    //     user?.extraPermissions,
    //     ModelCode.BOOK_STOCK,
    //     MethodCode.DELETE
    //   );
    //   const hasPermissionEditBookStock = findPermission(
    //     user?.permissionGroup,
    //     user?.extraPermissions,
    //     ModelCode.BOOK_STOCK,
    //     MethodCode.EDIT
    //   );
    return {
        // hasPermissionEditBookStock,
        hasPermissionCreateBookSaleTransaction: hasPermissionCreateBookSaleTransaction,
        hasPermissionListBookSaleTransaction: hasPermissionListBookSaleTransaction,
        hasPermissionViewBookSaleTransaction: hasPermissionViewBookSaleTransaction,
        // hasPermissionDeleteBookStock,
    };
};
export default bookSaleTransaction;
